import React, { useEffect, useState } from 'react'
import { client } from '../../services/apiService';
import * as AuthSelector from "../../store/auth/selectors";
import { useSelector } from "react-redux";
import AnalyticsSideNav from './AnalyticsSideNav';
import { toolTipHOC } from '../../CommonLogic';
import { Form } from 'react-bootstrap';

function Analytics() {
    const [iframeLink, setIframeLink] = useState("");
    const metabaseDetails = useSelector(AuthSelector.metabaseAccess);
    const userMetaInformation = useSelector(AuthSelector.userMetaInformation);
    const allDeparmentMetaInfo = useSelector(AuthSelector.allDeparmentMetaInfo);
    const [showWidth, setShowWidth] = useState(false);
    const [splitBy, setSplitBy] = useState("");
    const [splitByData, setSplitByData] = useState("");
    const [dashboardIdIndex, setDashboardIdIndex] = useState(0);
    const [active, setActive] = useState(0);

    async function getDynamicMetabase(payloadData) {
        const payload = { ...payloadData };
        const dashboardId = payload.data.dashboardId;
        if (typeof dashboardId == "object") {
            payload.data.dashboardId = payload.data.dashboardId[dashboardIdIndex];
        }
        const { data } = await client.post('/metabase/dynamic', payload);
        setIframeLink(data);
    }

    async function getStaticMetabase(){
        const {data}= await client.post('/metabase/static',{data:{
            dashboardId:metabaseDetails?.dashboardId
        }})
        setIframeLink(data)
    }

    function getUniqueDataBySplitBy(data, splitBy) {
        return data.reduce((acc, current) => {
          const value = current[splitBy];
          if (value && value.toLowerCase() !== 'dummy' && !acc.some((item) => item[splitBy] === value)) {
            acc.push(current);
          }
          return acc;
        }, []);
      }

    useEffect(() => {
        if (metabaseDetails?.type === 'Static') {
            getStaticMetabase();
        } else if (metabaseDetails?.type === 'Dynamic') {
            getDynamicMetabase({
                data: {
                    ...(userMetaInformation || metabaseDetails),
                    deparmentMetaInfo: allDeparmentMetaInfo[0]
                }
            });
        }

        if (userMetaInformation) {
            updateFilterData(userMetaInformation);
        } else if (metabaseDetails) {
            updateFilterData(metabaseDetails);
        }
        setActive(0);
    }, [metabaseDetails, allDeparmentMetaInfo, userMetaInformation, dashboardIdIndex]);

    const updateFilterData = (metaInfo) => {
        if (metaInfo?.splitBy) {
            const filterData = allDeparmentMetaInfo && getUniqueDataBySplitBy(
                allDeparmentMetaInfo, metaInfo.splitBy
            );
            setSplitByData(filterData);
            setSplitBy(metaInfo.splitBy);
            setShowWidth(filterData?.length > 1 && metaInfo.params[metaInfo.splitBy]);
        };
    };

    const handleFilter = (el) => {
        getDynamicMetabase({
            data: {
              ...(userMetaInformation || metabaseDetails),
              deparmentMetaInfo: {...allDeparmentMetaInfo?.[0], [splitBy]: el?.[splitBy]}
            }
          });
    }
    return (
        <>
            <div className='page-wrapper no_overflow_x'>
                <div className='content pb-0'>
                    <div className='row'>
                    {showWidth &&
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style analytics_sidebar_only'>
                            
                                <div className='sidebar-right'>
                                <p><span className='text_ellipses'>
                                {toolTipHOC(splitBy + " Wise", splitBy + " Wise", true, "top")} 
                                </span></p>
                                <ul className='page_nav'>
                                    <AnalyticsSideNav splitByData={splitByData || []} splitBy = {splitBy} handleFilter = {handleFilter} active={active} setActive={setActive}/>
                                </ul>
                            </div>
                            
                        </div>
                            }
                        <div className={`col-lg-12 col-md-12 col-sm-12 analytics_contentbar_only content_wrap_main content_wrap_style ${showWidth? '':'analytics_fullwidth'}`}>
                            <div className='content_wrap_comman'>
                                
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        {typeof (userMetaInformation?.dashboardId || metabaseDetails?.dashboardId) === "object" &&
                                            <Form.Select
                                                value={dashboardIdIndex}
                                                onChange={(e) => setDashboardIdIndex(e.target.value)}
                                                className=" form-select mb-3"
                                                aria-label=".form-select-lg example">
                                                {
                                                    (["Master Analytics", "Complete AI Audit Analytics"]).map((option, idx) =>
                                                    (
                                                        <option key={option} value={idx}>
                                                            {option}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Select>
                                        }
                                    </div>
                                </div>
                                <div className='analytics_dashboard_sec'>
                                    
                                    <iframe
                                        src={iframeLink}  
                                        width="100%"
                                        className="anlytics_iframe"
                                        title="Analytics Iframe" 
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default Analytics;