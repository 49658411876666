import React, { useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "./index.css";
import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
import { CustomTheme } from "./theme";
import { Modal } from "react-bootstrap";

function CustomSurvey({ json, submitTest, questionIds, exitSurvey, showWarningModel, setShowWarningModel }) {
    const creator = new Model(json)
    creator.applyTheme(CustomTheme);
    creator.showNavigationButtons = false;
    const [survey] = React.useState(creator);
    const [pageNo, setPageNo] = React.useState(survey.currentPageNo);
    const [isRunning, setIsRunning] = React.useState(true);

    survey.onCurrentPageChanged.add((_, options) => {
        setPageNo(options.newCurrentPage.visibleIndex);
    });
    survey.onStarted.add(() => { setIsRunning(true); });
    survey.onComplete.add(() => { setIsRunning(false); });
    survey.onComplete.add((sender, options) => {
        submitTest(sender.data, questionIds)
    });

    const prevPage = () => { survey.prevPage(); };
    const nextPage = () => { survey.nextPage(); };
    const endSurvey = () => { survey.completeLastPage(); };

    const renderButton = (text, func, canRender) => {
        if (!canRender) return undefined;
        return (
            <button className={`btn  ${text == 'Exit Test' ? "bordered_btn" : "comman_btn"}`} onClick={func}>
                {text}
            </button>
        );
    };

    const renderExternalNavigation = () => {
        if (!isRunning) return undefined;
        return (
            <div className="navigation-block">
                <div className="navigation-progress-container">
                    <div className="navigation-buttons-container">
                        {renderButton('Exit Test', exitSurvey, true)}
                        {renderButton('Previous Page', prevPage, pageNo !== 0)}
                        {renderButton('Next Page', nextPage, pageNo !== survey.visiblePages.length - 1)}
                        {renderButton('Submit', endSurvey, pageNo === survey.visiblePages.length - 1)}
                    </div>
                </div>
            </div>
        );
    };

    const handleExit = () => {
        endSurvey()
        submitTest({}, questionIds);
    };

    return (
        <div>
            <div className='test_ques_area survey_test scrollbar_style'>
                <Survey currentPageNo={pageNo} model={survey} />
            </div>
            <div className='text-end mt-4'>{renderExternalNavigation()}</div>
            {/* Model to show warning */}
            <Modal
                show={showWarningModel}
                onHide={() => setShowWarningModel(false)}
                backdrop='static'
                keyboard={false}
                className='exit_browser_modal'>
                <Modal.Header className='border-0' closeButton>
                    Are you sure you want to exit?
                </Modal.Header>
                <Modal.Body>
                    <div className='alret_success_body'>
                        {/* <h3 className='mb-3 h3 font-weight-500' style={{ marginTop: "-35px" }}></h3> */}

                        <p className='mt-2 mb-2'>
                            Note: If you go back, the test will be marked as
                            completed and can not be re-attempted.
                        </p>
                        <div className="text-end">
                            <button className="btn bordered_btn- exit_brow_btn" onClick={handleExit}>OK</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default CustomSurvey;
