import { createSlice } from '@reduxjs/toolkit'

const qaDashboardSlice = createSlice({
    name: 'qaDashboard',
    initialState: {
        dataToPrefill: {}
    },
    reducers: {
        setDataToPrefill(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.dataToPrefill = action.payload;
            return updatedInfo
        }
    }
})

export const {
    setDataToPrefill,setQueryParams
} = qaDashboardSlice.actions;

export default qaDashboardSlice.reducer
