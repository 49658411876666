/* eslint-disable no-unused-vars */
import React, { StrictMode, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// router
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
// reducer
import { store } from './store';
// import { IndexRouters } from "./router";
const Dashboard = lazy(() => import('./pages/Dashboard'));
const LoginPage = lazy(() => import('./components/Login/LoginPage'));
const Error404 = lazy(() => import('./views/dashboard/errors/error404'));
const Default = lazy(() => import('./layouts/dashboard/default'));
const AuthCheck = lazy(() => import('./components/AuthCheck/AuthPage'));
const AuditLists = lazy(() => import('./components/Audit/AuditLists'));
const AuditSheet = lazy(() => import('./components/Audit/AuditSheet'));
// import SingleAuditSheet from './components/Audit/SingleAuditSheet';
// import AuditSheetEdit from './components/Audit/AuditSheetEdit'
// import AuditSheetParams from './components/Audit/AuditSheetParams';
import PerformAudit from './views/modules/social/AuditSheet/PerformAudit';
import PerformAuditList from './views/modules/social/AuditSheet/PerformAuditList';
const Redirect404 = lazy(() => import('./components/Redirect404'));

// import QaDashboard from "./pages/QaDashboard";
// import QaManagerDashboard from "./pages/QaManagerDashboard";
const AvgAuditDashboard = lazy(() => import('./pages/AvgAuditDashboard'));
const WorkAllocationQa = lazy(() => import('./pages/WorkAllocationQa'));
const RebuttalPendingQa = lazy(() => import('./pages/RebuttalPendingQa'));
// import AgentDashboard from "./pages/AgentDashboard";
const QualityFunctionDashboard = lazy(
    () => import('./pages/QualityFunctionDashboard')
);
const AgentScoreDashboard = lazy(() => import('./pages/AgentScoreDashboard'));
const AgentErrorWiseDashboard = lazy(
    () => import('./pages/AgentErrorWiseDashboard')
);
const AgentAcceptancePending = lazy(
    () =>
        import('./views/modules/social/QualityDashboard/AgentAcceptancePending')
);
const QualityAssociateDashboard = lazy(
    () =>
        import(
            './views/modules/social/QualityDashboard/QualityAssociateDashboard'
        )
);
const QualityAssociatePendingDashboard = lazy(
    () => import('./pages/QualityAssociatePendingDashboard')
);
const FatalParameterDashboard = lazy(
    () => import('./pages/FatalParameterDashboard')
);
const FatalParameterFilterDashboard = lazy(
    () => import('./pages/FatalParameterFilterDashboard')
);
const FatalDashboardAcceptance = lazy(
    () =>
        import(
            './views/modules/social/QualityDashboard/FatalDashboardAcceptance'
        )
);
const MasterDashboard = lazy(
    () => import('./views/modules/social/QualityDashboard/MasterDashboard')
);
const AuditSheetDashboard = lazy(
    () => import('./views/modules/social/QualityDashboard/AuditSheetDashboard')
);

const ManualIntegration = lazy(
    () => import('./components/CRM/ManualIntegrationV2')
);
const ManualIntegrationEntries = lazy(
    () => import('./components/CRM/ManualIntegrationEntries')
);
const CreateFilter = lazy(() => import('./components/CRM/CreateFilter'));
const FilterList = lazy(() => import('./components/FilterSetup/FilterList'));
const AssignQA = lazy(() => import('./components/FilterSetup/AssignQA'));
const DashBoardTable = lazy(
    () => import('./components/DashboardTable/DashboardTable')
);
import {
    AgentScoreDashboardProps,
    QaScoreDashboardProps,
    AverageAuditDashboardProps,
    RebuttalPendingDashboardProps,
    ParameterWiseFatalDashboardProps,
} from './IndexProps/IndexProps';
import SurveyComponent from './components/Survey/SurveyComponent';
import CustomSurveyReadonly from './components/Survey/CustomSurveyReadonly';
import DIYOrganizationSetup from './components/DIY/DIYOrganizationSetup';
import DiyLandingScreen from './components/DIY/DiyLandingScreen';
import Analytics from './components/Analytics/Analytics';
const ReportAnalysis = lazy(
    () =>
        import(
            './components/KnowledgeManagement/KnowledgeManagementPKT/ReportAnalysis'
        )
);
const NoticeFeed = lazy(
    () => import('./components/KnowledgeManagement/NoticeFeed')
);
const ChangePassword = lazy(() => import('./components/Login/ChangePassword'));
const ProcessKnowledgeTest = lazy(
    () =>
        import(
            './components/KnowledgeManagement/KnowledgeManagementPKT/ProcessKnowledgeTest'
        )
);
const CreateTest = lazy(
    () =>
        import(
            './components/KnowledgeManagement/KnowledgeManagementPKT/CreateTest'
        )
);

const AgentTestResult = lazy(
    () =>
        import(
            './components/KnowledgeManagement/KnowledgeManagementPKT/AgentTestResult'
        )
);

const routerConfig = [
    {
        path: '/',
        element: <AuthCheck />,
        title: 'AAA',
    },
    {
        path: 'login',
        element: <LoginPage />,
    },
    {
        path: '/o/:orgId',
        element: <Default />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                path: 'avg-audit-dashboard',
                element: <AvgAuditDashboard />,
            },
            {
                path: 'work-allocation-qa',
                element: <WorkAllocationQa />,
            },
            {
                path: 'rebuttal-pending-qa',
                element: <RebuttalPendingQa />,
            },
            {
                path: 'quality-dashboard',
                element: <QualityFunctionDashboard />,
            },
            {
                path: 'agent-score-dashboard',
                element: (
                    <DashBoardTable
                        breadcrumb={AgentScoreDashboardProps.breadcrumb}
                        lastDivStyle={AgentScoreDashboardProps.lastDivStyle}
                        option={AgentScoreDashboardProps.option}
                        title={AgentScoreDashboardProps.title}
                        countData={AgentScoreDashboardProps.countData}
                        url={AgentScoreDashboardProps.url}
                    />
                ),
            },
            {
                path: 'agent-score-error-wise',
                element: <AgentErrorWiseDashboard />,
            },
            {
                path: 'agent-acceptance-pending',
                element: <AgentAcceptancePending />,
            },

            {
                path: 'quality-associate-dashboard',
                element: (
                    <DashBoardTable
                        breadcrumb={QaScoreDashboardProps.breadcrumb}
                        lastDivStyle={QaScoreDashboardProps.lastDivStyle}
                        option={QaScoreDashboardProps.option}
                        title={QaScoreDashboardProps.title}
                        countData={QaScoreDashboardProps.countData}
                        url={QaScoreDashboardProps.url}
                    />
                ),
            },
            {
                path: 'quality-associate-pending-dashboard',
                element: <QualityAssociatePendingDashboard />,
            },
            {
                path: 'fatal-parameter-dashboard',
                element: (
                    <DashBoardTable
                        breadcrumb={ParameterWiseFatalDashboardProps.breadcrumb}
                        lastDivStyle={
                            ParameterWiseFatalDashboardProps.lastDivStyle
                        }
                        option={ParameterWiseFatalDashboardProps.option}
                        title={ParameterWiseFatalDashboardProps.title}
                        countData={ParameterWiseFatalDashboardProps.countData}
                        url={ParameterWiseFatalDashboardProps.url}
                        refreshButton={
                            ParameterWiseFatalDashboardProps.refreshButton
                        }
                    />
                ),
            },
            {
                path: 'fatal-parameter-filter',
                element: <FatalParameterFilterDashboard />,
            },
            {
                path: 'fatal-acceptance-dashboard',
                element: <FatalDashboardAcceptance />,
            },
            {
                path: 'master-dashboard',
                element: <MasterDashboard />,
            },
            {
                path: 'auditsheet-dashboard',
                element: <AuditSheetDashboard />,
            },
            {
                path: 'audit-sheet/list',
                element: <AuditLists />,
            },
            {
                path: 'audit-sheet/create',
                element: <AuditSheet mode={'Create'} />,
            },
            {
                path: 'audit-sheet/list/:sheetId',
                element: <AuditSheet mode={'Edit'} />,
            },
            // MANUAL INTEGRTION (CRM)
            {
                path: 'manual-integration',
                element: <ManualIntegration />,
            },
            {
                path: 'manual-integration-entries',
                element: <ManualIntegrationEntries />,
            },
            {
                path: 'manual-integration-entries/assign-to-qa',
                element: <AssignQA />,
            },
            {
                path: 'filters/create',
                element: <CreateFilter />,
            },
            {
                path: 'filters/:id',
                element: <CreateFilter />,
            },
            {
                path: 'filters',
                element: <FilterList />,
            },
            {
                path: 'perform-audit/:ticketId',
                element: <PerformAudit />,
            },
            {
                path: 'perform-audit/list',
                element: <PerformAuditList />,
            },
            {
                path: 'view-my-audit/list',
                element: <PerformAuditList showFor={'Agent'} mode='View' />,
            },
            {
                path: 'view-audit/:ticketId',
                element: <PerformAudit showFor={'Agent'} mode='View' />,
            },
            {
                path: 'edit-audit/:ticketId',
                element: <PerformAudit showFor={'Agent'} mode='Edit' />,
            },
            {
                path: '*',
                element: <Redirect404 />,
            },

            {
                path: 'rebuttal-pending-dashboard',
                element: (
                    <DashBoardTable
                        breadcrumb={RebuttalPendingDashboardProps.breadcrumb}
                        lastDivStyle={
                            RebuttalPendingDashboardProps.lastDivStyle
                        }
                        option={RebuttalPendingDashboardProps.option}
                        title={RebuttalPendingDashboardProps.title}
                        countData={RebuttalPendingDashboardProps.countData}
                        url={RebuttalPendingDashboardProps.url}
                    />
                ),
            },

            {
                path: 'average-audit-dashboard',
                element: (
                    <DashBoardTable
                        breadcrumb={AverageAuditDashboardProps.breadcrumb}
                        lastDivStyle={AverageAuditDashboardProps.lastDivStyle}
                        option={AverageAuditDashboardProps.option}
                        title={AverageAuditDashboardProps.title}
                        countData={AverageAuditDashboardProps.countData}
                        url={AverageAuditDashboardProps.url}
                    />
                ),
            },
            {
                path: 'knowledge-management/notice-feed',
                element: <NoticeFeed />,
            },
            {
                path: 'knowledge-management/process-knowledge-test',
                element: <ProcessKnowledgeTest />,
            },
            {
                path: 'setup/org-setup',
                element: <DIYOrganizationSetup />,
            },
            {
                path: 'setup/user-management',
                element: <DiyLandingScreen />,
            },
            {
                path: 'knowledge-management/process-knowledge-test/knowledge-assessment',
                element: <SurveyComponent />,
            },
            {
                path: 'knowledge-management/create-test',
                element: <CreateTest />,
            },
            {
                path: 'knowledge-management/report-analysis/:pktId',
                element: <ReportAnalysis />,
            },
            {
                path: 'knowledge-management/process-knowledge-test/result',
                element: <AgentTestResult />,
            },
            {
                path: 'profile-settings',
                element: <ChangePassword />,
            },
            {
                path: 'knowledge-management/process-knowledge-test/readonly',
                element: <CustomSurveyReadonly />,
            },
            {
                path: 'analytics',
                element: <Analytics />,
            },
        ],
    },
    // ...IndexRouters,
    {
        path: '*',
        element: <Error404 />,
    },
];

const router = createBrowserRouter(routerConfig, {
    basename: process.env.PUBLIC_URL,
});
const basename = process.env.REACT_APP_BASENAME || null;

ReactDOM.createRoot(document.getElementById('root')).render(
    <StrictMode>
        <Provider store={store}>
            <App>
                <Suspense fallback={<div>...loading</div>}>
                    <RouterProvider
                        router={router}
                        basename={basename}
                    ></RouterProvider>
                </Suspense>
            </App>
        </Provider>
    </StrictMode>
);
