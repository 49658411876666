import React from 'react';
// scss
import 'shepherd.js/dist/css/shepherd.css';
import 'flatpickr/dist/flatpickr.css';
import 'choices.js/public/assets/styles/choices.min.css';
import './assets/scss/hope-ui.scss';
import './assets/scss/pro.scss';
import './assets/scss/custom.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/customizer.scss';
import './assets/custom/scss/custom.scss';
import './assets/custom/css/loginStyle.css';
import './assets/custom/css/main.css';
import './assets/custom/css/knowledgemanagement.css';
import './assets/custom/css/custom_style.css';
import 'react-toastify/dist/ReactToastify.css';

// Redux Selector / Action
import { useDispatch } from 'react-redux';

// import state selectors
import { setSetting } from './store/setting/actions';

function App({ children }) {
    const dispatch = useDispatch();
    dispatch(setSetting());

    return <div className='App'>{children}</div>;
}

export default App;
