import React, { useState } from 'react';
import * as AuthSelectors from '../../../src/store/auth/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const KnowledgeMngtSideNav = ({ tab = 'notice-feed' }) => {
    const orgStrId = useSelector(AuthSelectors.orgStrId);
    const navigate = useNavigate();
    const [active, setActive] = useState(tab);

    const handleNavLink = activeNav => {
        if (active == activeNav) {
            return;
        }
        setActive(activeNav);
        navigate(`/o/${orgStrId}/knowledge-management/${activeNav}`);
    };
    return (
        <>
            <li>
                <a
                    className={active == 'notice-feed' ? 'active' : ''}
                    href='#'
                    onClick={e => {
                        e.preventDefault();
                        handleNavLink('notice-feed');
                    }}
                >
                    Notice Feed
                </a>
            </li>
            <li>
                <a
                    className={
                        active == 'process-knowledge-test' ? 'active' : ''
                    }
                    href='#'
                    onClick={e => {
                        e.preventDefault();
                        handleNavLink('process-knowledge-test');
                    }}
                >
                    Process Knowledge Test
                </a>
            </li>
        </>
    );
};

export default KnowledgeMngtSideNav;
