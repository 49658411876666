import React, { useRef, useEffect } from "react";
import { useBlocker } from "react-router-dom";

function useOutsideAlerter(ref, isActive, questionIds, proceedFunction, submitRef) {
  const shouldBlock = (({ currentLocation, nextLocation }) =>
    submitRef.current == false && currentLocation.pathname !== nextLocation.pathname);

  const blocker = useBlocker(shouldBlock);

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target) && !isActive || blocker.state == 'blocked') {
      const shouldNavigate = window.confirm('Are you sure you want to leave this Page?');
      if (!shouldNavigate) {
        if (blocker.state == 'blocked') {
          blocker.reset();
        }
        return;
      } else {
        if (blocker.state == 'blocked') {
          blocker.proceed();
          blocker.reset()
        }

        if (proceedFunction && typeof proceedFunction === "function") {
          submitRef.current = true;
          proceedFunction({}, questionIds);
        }
        return;
      }
    }
  }
  useEffect(() => {
    if (isActive == false) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isActive, questionIds, blocker, submitRef.current]);
}
/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter({ children, isActive, questionIds, onProceed, submitRef }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, isActive, questionIds, onProceed, submitRef);
  return <div ref={wrapperRef}>{children}</div>;
}