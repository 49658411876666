import axios, { CancelToken } from 'axios';

const BASE = process.env.REACT_APP_API_URL;

interface ApiResponse {
    success: Boolean;
    data?: any;
    error?: any;
}

export const client = {
    /**
     *
     * @param {*} url
     * @param {*} options
     * @param {*} isProtected
     */
    get: async (uri, options: any = {}, isProtected = true, cancelToken?: CancelToken) => {
        try {
            if (isProtected) {
                if (!options.headers) options.headers = {};
                let orgId = getOrgId();
                options.headers['x-org-id'] = orgId;
                options.headers['Authorization'] =
                    `Bearer ${localStorage.getItem('userToken')}`;
            }
            const res = await axios.get(BASE + uri, { ...options, cancelToken });
            const data = res.data;
            return {
                success: true,
                data: data.data || data,
                meta: data.meta,
            } as ApiResponse;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
            return {
                success: false,
                error,
            } as ApiResponse;
        }
    },
    post: async (uri, body, options: any = {}, isProtected = true, cancelToken?: CancelToken) => {
        try {
            if (isProtected) {
                if (!options.headers) options.headers = {};
                options.headers['x-org-id'] = getOrgId();
                options.headers['Authorization'] =
                    `Bearer ${localStorage.getItem('userToken')}`;
            }
            const { data } = await axios.post(BASE + uri, body, { ...options, cancelToken });
            return {
                success: true,
                data,
            } as ApiResponse;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
            return {
                success: false,
                error,
            } as ApiResponse;
        }
    },
    put: async (uri, body, options: any = {}, isProtected = true, cancelToken?: CancelToken) => {
        try {
            if (isProtected) {
                if (!options.headers) options.headers = {};
                options.headers['x-org-id'] = getOrgId();
                options.headers['Authorization'] =
                    `Bearer ${localStorage.getItem('userToken')}`;
            }
            const { data } = await axios.put(BASE + uri, body, { ...options, cancelToken });
            return {
                success: true,
                data,
            } as ApiResponse;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
            return {
                success: false,
                error,
            } as ApiResponse;
        }
    },
    delete: async (uri, body, options: any = {}, isProtected = true, cancelToken?: CancelToken) => {
        try {
            if (isProtected) {
                if (!options.headers) options.headers = {};
                options.headers['x-org-id'] = getOrgId();
                options.headers['Authorization'] =
                    `Bearer ${localStorage.getItem('userToken')}`;
            }
            const { data } = await axios.delete(BASE + uri, { ...options, cancelToken });
            return {
                success: true,
                data,
            } as ApiResponse;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
            return {
                success: false,
                error,
            } as ApiResponse;
        }
    },
};

const getOrgId = () => {
    let url = window.location.href;
    let temp = url.split('//')[1].split('/');
    for (let i in temp) {
        if (temp[i] === 'o') {
            let next = Number(i + '') + 1;
            return temp[next];
        }
    }
    return undefined;
};