export const RC_REBUTTAL = 'Rebuttal';
export const RC_ACTION_REBUTTAL = 'Rebuttal';
export const RC_REBUTTAL_REJECTED = 'Rebuttal Rejected';
export const RC_AUDIT_REVIEWED = 'Audit Reviewed';
export const RC_ACCEPT = 'Accept';
export const RC_ACCEPTED = 'Accepted';
export const RC_ACTION_ACCEPT = 'Accept';
export const RC_AUDIT_DONE = 'Audit Done';
export const RC_AUDIT_EDITED = 'Audit Edited';
export const RC_REJECT = 'Reject';
export const RC_ACTION_REJECT = 'Reject';
export const RC_AGENT = 'Agent';
export const NF_AGENT = 'Agent';
export const RC_ACTION_ALL = 'All';
export const RC_COMMENTS = 'Comments';
export const RC_ACTION_COMMENTS = 'Comments';
export const RC_NO_COMMENT = '--SYS-NO-COMMENT--';
export const RC_ACTION_COMMENT = 'comment';
export const RC_COMMENT = 'comment';
export const RC_HISTORY = 'History';
export const RC_ACTION_HISTORY = 'History';
export const RC_ACTION_EDITED = 'edited';
export const RC_ACTION_REBUTTAL_SMALL_CASE = 'rebuttal';
export const RC_ACTION_ACCEPTED = 'accepted';
export const RC_ACTION_REBUTTAL_REJECTED = 'rebuttal-rejected';
export const RC_EDIT = 'Edit';
export const NF_MARK_ALL = 'Mark All';
export const NF_API = 'api';
export const SM_PKT = 'process-knowledge-test'; // sidebar-menu.js
export const SM_NF = 'notice-feed';
export const SM_CREATE_TEST = 'create-test';
export const CT_QUESTION = 'question';
export const CT_MARKS = 'marks';
export const CT_CHOICE = 'choice';
export const CT_CORRECT_OPTION = 'correctOption';
export const CT_TIME_LIMIT_HOUR = 'timeLimitHour';
export const CT_TIME_LIMIT_MINUTE = 'timeLimitMinute';
export const CT_PASSING_MARKS = 'passingMarks';
export const CT_PUBLISH_PKT_SCREEN = 'publishPktScreen';
export const CT_ADD_QUESTION_SCREEN = 'addQuestionScreen';
export const PKT_UNASSIGNED = 'UNASSIGNED';
export const PKT_INPROGRESS = 'INPROGRESS';
export const PKT_ASSIGNED = 'ASSIGNED';
export const PKT_COMPLETED = 'COMPLETED';
export const PKT_AGENT_PENDING = 'Pending';
export const PKT_AGENT_NOT_ATTENDED = 'Not Attended';
export const PKT_AGENT_COMPLETED = 'Completed';
export const PA_AGENT = 'Agent';
export const PA_CUSTOMER = 'Customer';
