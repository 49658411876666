import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import CustomSurvey from './CustomSurvey';
import KnowledgeMngtSideNav from '../KnowledgeManagement/KnowledgeMngtSideNav';
import { ErrorNotify, imagePath } from '../../CommonLogic';
import { client } from '../../services/apiService';
import * as AuthSelectors from "../../../src/store/auth/selectors";
import { useSelector } from 'react-redux';
import OutsideAlerter from './OutsideAlerter';

function SurveyComponent() {
    const orgStrId = useSelector(AuthSelectors.orgStrId);
    const navigate = useNavigate();
    const [testJson, setTestJson] = useState({});
    const [testName, setTestName] = useState('')
    const location = useLocation();
    const pktId = location.state?.feed?.id;
    const [questionIds, setQuestionIds] = useState({});
    const singleFunctionCall = useRef(false);
    const [showWarningModel, setShowWarningModel] = useState(false);
    const [exitAssignment, setExitAssignment] = useState(false);
    const submitRef = useRef(false);

    async function submitTest(payload, questionIds) {
        submitRef.current=true
        setShowWarningModel(false)
        if (singleFunctionCall.current) {
            return;
        }
        singleFunctionCall.current = true;
        const originalObject = payload;
        const convertedObject = questionIds;
        for (const key in originalObject) {
            const numericKey = parseInt(key);
            if (
                convertedObject.hasOwnProperty(numericKey) &&
                !convertedObject[numericKey].includes(originalObject[key])
            ) {
                convertedObject[numericKey].push(+originalObject[key]);
            }
        }
        testJson.testName = testName;
        const response = await client.put(`/pkt-assigned-users/${pktId}`, {
            data: convertedObject,
        });
        if (response.success) {
            navigate(`/o/${orgStrId}/knowledge-management/process-knowledge-test/readonly`, { state: { testData: testJson, selectedOptions: payload, exitAssignment: exitAssignment } });
        }
    }

    useEffect(()=>{
        if(location&&location.state==null&&orgStrId)
        {
            submitRef.current=true;
            navigate(`/o/${orgStrId}/knowledge-management/process-knowledge-test`)
        }
    },[orgStrId])

    const modifyData = data => {
        const questionId = {};
        const modifiedData = JSON.parse(JSON.stringify(data));
        modifiedData.name = String(modifiedData.name);
        delete modifiedData.title;
        if (modifiedData.pages && modifiedData.pages.length > 0) {
            modifiedData.pages.forEach(page => {
                if (page.elements && page.elements.length > 0) {
                    page.elements.forEach(element => {
                        if (questionId[element.name] == undefined) {
                            questionId[element.name] = [];
                        }
                        (element.showClearButton = true),
                            (element.name = String(element.name));
                        if (element.choices && element.choices.length > 0) {
                            element.choices.forEach(choice => {
                                choice.value = String(choice.value);
                            });
                        }
                    });
                }
            });
        }
        setQuestionIds(questionId);
        return modifiedData;
    };

    useEffect(() => {
        const fetchQuestionAnswer = async pktId => {
            try {
                const response = await client.get(
                    `/pkt-assigned-users/${pktId}`
                );
                if (response?.success) {
                    setTestName(response?.data?.title);
                    setTestJson(modifyData(response.data));
                }
            } catch (error) {
                ErrorNotify('Error fetching question answer');
            }
        };

        if (pktId) {
            fetchQuestionAnswer(pktId);
        }
    }, [pktId]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          submitTest({},questionIds);
          const message = 'Are you sure you want to leave?';
          event.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [questionIds]);

    const exitSurvey = () => {
        setExitAssignment(true);
        setShowWarningModel(true);
    }

    return (
        <>
            <div className='page-wrapper no_overflow_x'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>Knowledge Management</p>
                                <ul className='page_nav'>
                                    <KnowledgeMngtSideNav
                                        tab={'process-knowledge-test'}
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    aria-current='page'
                                                    href={`/o/${orgStrId}/knowledge-management/process-knowledge-test`}>
                                                        
                                                    Process Knowledge Test
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    {testName}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <OutsideAlerter isActive={showWarningModel} questionIds={questionIds} onProceed={submitTest} submitRef={submitRef}>
                                    <div className='two_column_title padd_right_timer'>
                                        <h3 className='page_title'>
                                            {testName}
                                        </h3>
                                    </div>
                                    <div className='test_ques_wrap mt-4'>
                                        <div>
                                            {Object.keys(testJson).length > 0 && (
                                                <CustomSurvey
                                                    json={testJson}
                                                    submitTest={submitTest}
                                                    questionIds={questionIds}
                                                    exitSurvey={exitSurvey}
                                                    showWarningModel={showWarningModel}
                                                    setShowWarningModel={setShowWarningModel}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </OutsideAlerter>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SurveyComponent;
