import {authSlice, validateToken, loginUser} from './reducers'

export const {
    getLoggedInUserSettings,
    setLoginError,
    getAudioConfig
} = authSlice.actions;

export {
    validateToken,
    loginUser
}