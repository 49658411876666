import React, { useState, useEffect } from 'react';
import { imagePath } from '../../../../CommonLogic';
export const WaveformGenerator = ({
    currTime,
    totalSeconds,
    totalBars,
    onClick,
}) => {
    const [waveformData, setWaveformData] = useState(null);
    const durationPerBar = totalSeconds / totalBars;

    useEffect(() => {
        createAudioContextAndFetch();
    }, []);

    const createAudioContextAndFetch = async () => {
        try {
            const audioContext = new (window.AudioContext ||
                window.webkitAudioContext)();
            const response = await fetch(imagePath('/sample-12s.mp3'));
            const arrayBuffer = await response.arrayBuffer();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            visualize(audioBuffer);
        } catch (error) {
            console.error('Error fetching or decoding audio:', error);
        }
    };

    const visualize = audioBuffer => {
        const filtered = filterData(audioBuffer, totalBars);
        const normalized = normalizeData(filtered);
        setWaveformData(normalized);
    };

    // Define your filterData and normalizeData functions here
    /**
     * Filters the AudioBuffer retrieved from an external source
     * @param {AudioBuffer} audioBuffer the AudioBuffer from drawAudio()
     * @returns {Array} an array of floating point numbers
     */

    const filterData = (audioBuffer, totalBars) => {
        const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
        const samples = totalBars; // Number of samples we want to have in our final data set
        const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
        const filteredData = [];
        for (let i = 0; i < samples; i++) {
            const blockStart = blockSize * i; // the location of the first sample in the block
            let sum = 0;
            for (let j = 0; j < blockSize; j++) {
                sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
            }
            filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
        }
        return filteredData;
    };

    /**
     * Normalizes the audio data to make a cleaner illustration
     * @param {Array} filteredData the data from filterData()
     * @return {Array} an normalized array of floating point numbers
     */
    const normalizeData = filteredData => {
        const multiplier = Math.pow(Math.max(...filteredData), -1);
        return filteredData.map(n => n * multiplier);
    };

    return (
        <div>
            {/* <button onClick={createAudioContextAndFetch}>Load Audio</button> */}
            {waveformData && (
                <div className='waveform'>
                    {waveformData.map((wd, i) => (
                        <Bar
                            key={i}
                            size={wd / 4}
                            index={i}
                            onClick={onClick}
                            currTime={currTime}
                            durationPerBar={durationPerBar}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const Bar = ({ currTime, durationPerBar, size, index, onClick }) => {
    const barStartTime = durationPerBar * index;
    const barEndTime = barStartTime + durationPerBar;
    const hasBeenPlayed = barEndTime <= currTime;
    const isBeingPlayed = currTime > barStartTime && currTime < barEndTime;
    let currBarDuration = 0;
    if (isBeingPlayed) {
        currBarDuration = (currTime - barStartTime) / durationPerBar;
    }

    const onBarClick = () => onClick(barStartTime + durationPerBar / 2);

    const background = hasBeenPlayed ? '#52D017' : '#23ce6b';
    const fullBarHeight = size * 100;

    return (
        <div className='barHolder' onClick={onBarClick}>
            <div
                className='bar'
                style={{ height: fullBarHeight, background }}
            />
            {isBeingPlayed && (
                <div className='barOverHolder'>
                    <div
                        className='barOver'
                        style={{ height: currBarDuration * fullBarHeight }}
                    />
                </div>
            )}
        </div>
    );
};
