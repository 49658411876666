import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import { client } from '../../services/apiService';
import _ from 'lodash';

export const auditSheetSlice = createSlice({
    name: 'auditSheet',
    initialState,
    reducers: {
        setDepartmentId(state, action) {},
        setTat(state, action) {},
    },
    extraReducers(builder) {
        builder.addCase(getAuditSheets.pending, (state, action) => {
            state.isLoading = true;
            state.auditSheetList = [];
            state.pageCount = 1;
        });
        builder.addCase(getAuditSheets.fulfilled, (state, action: any) => {
            let temp = JSON.parse(JSON.stringify(action.payload.data));
            let pc = action.payload.meta?.pagination?.pageCount;
            state.auditSheetList = temp;
            state.pageCount = pc || 1;
            state.isLoading = false;
        });
        builder.addCase(getAuditSheetHistory.pending, (state, action) => {
            state.isLoading = true;
            state.pageCountHistory = 1;
            state.auditSheetHistory = [];
        });
        builder.addCase(getAuditSheetHistory.fulfilled, (state, action) => {
            state.auditSheetHistory = action.payload.data;
            state.pageCountHistory = (
                action.payload as any
            ).meta?.pagination?.pageCount;
            state.isLoading = false;
        });
        builder.addCase(getSingleAuditSheet.pending, (state, action) => {
            state.isLoading = true;
            state.auditSheet = null;
        });
        builder.addCase(getSingleAuditSheet.fulfilled, (state, action) => {
            let temp = action.payload.data[0];
            temp.attributes.Audit_Parameters.data = _.orderBy(
                temp.attributes.Audit_Parameters.data,
                ['id'],
                ['asc']
            );
            const dataFromApi = action.payload.data[0];
            state.auditSheet = updateAuditOptions(dataFromApi); //for Audit_L_2_Options in plain array
            state.isLoading = false;
        });
    },
});

function updateAuditOptions(obj) {
    const updatedObj = JSON.parse(JSON.stringify(obj));
    updatedObj.attributes.Audit_Parameters.data.forEach(parameter => {
        parameter.attributes.Audit_Subparameters.data.forEach(subparameter => {
            subparameter.attributes.Audit_L_2_Options =
                subparameter?.attributes?.Audit_L_2_Options?.data.map(
                    option => option.attributes.title
                );
        });
    });
    return updatedObj;
}

export const getAuditSheets = createAsyncThunk(
    'audit-sheets/fetch',
    async ({ page = 1, pageSize = 10, search = '' }: any) => {
        const response = await client.get(
            `/custom-form-filter?pagination[page]=${page || 1}&pagination[pageSize]=${pageSize}&[Is_Active][$eq]=true&[search]=${search}`
        );
        return response;
    }
);

export const getAuditSheetHistory = createAsyncThunk(
    'audit-sheets/history',
    async ({ department, page, pageSize = 10, setLoading = e => e }: any) => {
        const response = await client.get(
            `/custom-form-filter?[Department][id]=${department}&pagination[page]=${page || 1}&pagination[pageSize]=${pageSize}`
        );
        setLoading(false);
        return response;
    }
);

export const getSingleAuditSheet = createAsyncThunk(
    'audit-sheets/sheet',
    async sheetId => {
        const response = await client.get(
            `/audit-forms?filters[id][$eq]=${sheetId}&populate[0]=Created_By.username&populate[1]=Department&sort=Version:desc&populate[2]=Audit_Parameters.Audit_Subparameters&populate[3]=Audit_Parameters.Audit_Subparameters.Audit_L_2_Options`
        );
        return response;
    }
);

export default auditSheetSlice.reducer;
