import {auditSheetSlice, getAuditSheets, getAuditSheetHistory, getSingleAuditSheet} from './reducers'

export const {
    setDepartmentId,
    setTat,
    // setAuditUrl,
} = auditSheetSlice.actions;

export {
    getAuditSheets,
    getAuditSheetHistory,
    getSingleAuditSheet
}

