import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isStringControl, isEnumControl, and } from '@jsonforms/core';

const DropdownFormCtrl = props => {
    const { label = '', id, isValid, path, errors, data } = props;
    const { handleChange, require, placeholder, disabled } = props.schema;
    const options = props.schema && props.schema.enum ? props.schema.enum : [];

    return (
        <>
            <Form.Label htmlFor='validationDefault01'>
                {label}
                {require && <sup className='sup_manatory text-danger'>*</sup>}
            </Form.Label>
            <Form.Select
                onChange={handleChange}
                name={placeholder}
                required={require}
                defaultValue={data}
                disabled={disabled}
            >
                <option value={''}>Select {label}</option>
                {options?.map(el => {
                    return (
                        <option key={'row-option-' + el} value={el}>
                            {el}
                        </option>
                    );
                })}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>
                Please select {label}
            </Form.Control.Feedback>
        </>
    );
};

export const DropdownFormControl = withJsonFormsControlProps(DropdownFormCtrl);

export const DropdownFormTester = rankWith(
    5, // increase rank as needed
    and(isEnumControl, isStringControl)
);
