import React, { useEffect, useState } from 'react';
import KnowledgeMngtSideNav from '../KnowledgeManagement/KnowledgeMngtSideNav';
import { Breadcrumb, Modal } from 'react-bootstrap';
import { delay, imagePath } from '../../CommonLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import * as AuthSelectors from "../../../src/store/auth/selectors";
import { useSelector } from 'react-redux';

function CustomSurveyReadonly() {
    const orgStrId = useSelector(AuthSelectors.orgStrId);
    const navigate = useNavigate();
    const location = useLocation();
    const [congratsModal, setCongratsModal] = useState(false);
    const [exitAssignment, setExitAssignment] = useState(false);
    const [pktData,setPktData]=useState({});
    const [selectedOptions,setSelectedOptions]=useState({})

    useEffect(() => {
        if (location.state?.exitAssignment && location.state?.testData && location.state?.selectedOptions && Object.keys(location.state.selectedOptions).length === 0) {
            setPktData(location.state.testData);
            setExitAssignment(Object.keys(location.state.selectedOptions).length === 0?true:false);
        }
        else {
            setPktData(location.state.testData);
            setSelectedOptions(location.state.selectedOptions);
            setExitAssignment(Object.keys(location.state.selectedOptions).length === 0?true:false)
            DataAvailable(Object.keys(location.state.selectedOptions).length === 0?true:false);
        }
    }, [location.state])

    async function DataAvailable(exitedAssignment){
        if(!exitedAssignment)
        {
            setCongratsModal(true);
        }
        await delay(2000);
        setExitAssignment(false)
        setCongratsModal(false);
         navigate(`/o/${orgStrId}/knowledge-management/process-knowledge-test`);
    }

    function secondsToHHMM(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
    return (
        <>
            <div className='page-wrapper no_overflow_x'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>Knowledge Management</p>
                                <ul className='page_nav'>
                                    <KnowledgeMngtSideNav
                                        tab={'process-knowledge-test'}
                                    />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item as='li' aria-current='page'>
                                                    Process Knowledge Test
                                                </Breadcrumb.Item>

                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    {pktData.testName}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='two_column_title'>
                                    <h3 className='page_title'>
                                        {pktData.testName}
                                    </h3>
                                </div>
                                <div className='test_ques_wrap mt-4'>
                                    <div>
                                        <div className='test_ques_area survey_test scrollbar_style timer_display exam_disabled'>
                                            <div className='test_res_report_area'>
                                                {pktData?.pages && pktData?.pages.length > 0 && pktData?.pages[0].elements?.map((e, i) => {
                                                    return (<div className='test_result_box' key={i}>
                                                        <h4>{i + 1}. {e.title}</h4>
                                                        <ul>
                                                            {e.choices.map(choice => (
                                                                <li
                                                                    key={choice.value}
                                                                    className={`${selectedOptions[e.name] !== undefined && selectedOptions[e.name].includes(choice.value)?'attempted_answer':''}`}
                                                                >
                                                                    <span className='circle'></span>
                                                                    {choice.text}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                        <div className='timer_box'>
                                            <div>
                                                <h4 className='mb-0'>{secondsToHHMM(pktData?.maxTimeToFinish?pktData?.maxTimeToFinish:1800)}</h4>
                                            </div>
                                            <div>
                                                <p className='mb-0'>0:00</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={congratsModal && !exitAssignment}
                backdrop='static'
                keyboard={false}
                className='alret_success_modal'>
                <Modal.Body>
                    <div className='alret_success_body text-center'>
                        <img
                            src={imagePath('/images/congratulation_icon.svg')}
                            alt=''
                        />
                        <h4 className='mt-4'>Congratulations</h4>
                        <p className='mt-4'>
                            You have successfully completed the assessment.
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CustomSurveyReadonly;
