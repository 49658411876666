export const initialState = {
    auditSheetList: [],
    pageCount: 1,
    pageCountHistory: 1,
    auditSheetHistory: [],
    isLoading: false,
    auditSheet:{},
    // auditUrl: "",
}

