const ONLYALPHANUM = /^[a-zA-Z0-9]+$/;
const DATE_REGEX = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-(\d{4})/;
const CSATDSAT = /^(CSAT)|(DSAT)$/;
const CONNECTED_BY = /^(Agent)|(Customer)$/;
const HH_MM_SS = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;

export const initialState: AuthState = {
  isLoggedIn: true,
  organization: {
    orgStrId: null,
    organisationName: null,
  },
  prefix: null,
  columns: {
    "Ticket/Call Id": {
      validate: {
        type: "regexp",
        regexp: "^[a-zA-Z0-9]+$",
        "function": "[Date].replace(/[-\/]/gi, '') + [DEP] + [(new Date().getTime()).toString().substr(-4)] + [ROW]"
      },
    },
    "Agent Id": {
      validate: {
        type: "regexp",
        regexp: "^[a-zA-Z0-9]+$",
      },
      required: true,
    },
    "Date": {
      validate: {
        type: "date",
        format: "MM-DD-YYYY"
      },
    },
    "CSAT/DSAT": {
      validate: {
        type: "regexp",
        regexp: "^(CSAT)|(DSAT)$",
      },
    },
    "Disconnect by": {
      validate: {
        type: "regexp",
        regexp: "^(Agent)|(Customer)$",
      },
    },
    "Duration": {
      validate: {
        type: "regexp",
        regexp: "^([0-9]{0,2}):?[0-9]{0,2}:[0-9]{0,2}$",
      },
      key: "Call Duration"
    },
    "Recording File": {
      validate: {
        type: "any"
      },
    }
  },
  loggedInUser: {
    userName: null,
    email: null,
    role: null,
    orgRole: null,
    firstName: null,
    lastName: null,
    currentPassword: "",
  },
  loginError: null,
  accessPermissions: [],
};
