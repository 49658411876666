import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isStringControl, isDateControl, and } from '@jsonforms/core';
import Flatpickr from 'react-flatpickr';
import { Form } from 'react-bootstrap';
import * as React from 'react';

const DateFormCtrl = props => {
    const { label = '', data } = props;
    const { handleChange, require, placeholder, disabled } = props.schema;
    const [customDate, setCustomDate] = React.useState('');
    const [renderDate, setRenderDate] = React.useState(false);

    const CustomFormControl = React.forwardRef(
        (
            {
                id,
                placeholder,
                handleChange,
                isNotValid,
                label,
                disabled,
                data,
                setCustomDate,
            },
            ref
        ) => {
            return (
                <Flatpickr
                    id={id}
                    options={{
                        enableTime: false,
                        dateFormat: 'd-M-Y',
                        defaultDate: null,
                    }}
                    placeholder={`Select ${label}`}
                    onChange={(selectedDates, dateStr, instance) => {
                        setCustomDate(dateStr);
                        handleChange({
                            name: placeholder,
                            value: selectedDates,
                        });
                        setRenderDate(true);
                    }}
                    value={data && !renderDate ? data : customDate}
                    disabled={disabled}
                    style={{
                        background: disabled ? 'rgb(233,236,239)' : 'white',
                        cursor: disabled ? 'auto' : 'pointer',
                        fontSize: '12px',
                    }}
                    className={`flatepickr_datetime form-control ${isNotValid ? 'invalid' : ''}`}
                />
            );
        }
    );

    return (
        <>
            <Form.Label md='6' htmlFor='validationDefault01'>
                {label}
                {require && <sup className='sup_manatory text-danger'>*</sup>}
            </Form.Label>
            <Form.Control
                id={require ? 'require' : ''}
                as={CustomFormControl}
                isNotValid={require && !customDate ? true : false}
                required={require}
                placeholder={placeholder}
                style={{ padding: 0, display: 'flex', alignItems: 'center' }}
                label={label}
                handleChange={handleChange}
                data={data}
                disabled={disabled}
                setCustomDate={setCustomDate}
            ></Form.Control>
            <Form.Control.Feedback type='invalid' id='datePickerError'>
                Please select {label}
            </Form.Control.Feedback>
        </>
    );
};

export const DateFormControl = withJsonFormsControlProps(DateFormCtrl);

export const DateFormTester = rankWith(5, and(isStringControl, isDateControl));
