import React, { useEffect, useState } from 'react';
import { Breadcrumb, Modal, Tab, Nav, Button, Form, Spinner } from 'react-bootstrap';
import 'react-dropdown-tree-select/dist/styles.css';
import {
    ErrorNotify,
    imagePath,
    loadingData,
    successNotify,
    toolTipHOC,
} from '../../CommonLogic';
import DIYSideNav from './DIYSideNav';
import * as XLSX from 'xlsx';
import { client } from '../../services/apiService';
import { validateToken } from '../../store/auth/reducers';
import { useDispatch, useSelector } from 'react-redux';
import UserForm from './UserForm';
import PaginationBar from '../partials/PaginationBar';
import { useSearchParams } from 'react-router-dom';
import * as AuthSelector from '../../store/auth/selectors';
import csvDownload from 'json-to-csv-export';
import * as moment from 'moment';


const DiyLandingScreen = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organization = useSelector(AuthSelector.organization);
    const [show4, setShow4] = useState(false);
    const handleCloseSingleUserModal = () => {
        setShow4(false);
        setShowUserEditModal(false);
    };
    const handleShow4 = () => setShow4(true);
    const [show3, setShow3] = useState(false);
    const [designation, setDesignation] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [dashboardRole, setDashboardRole] = useState([]);
    const [dashboardUserData, setDashboardUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dashboardUserDataCopy, setDashboardUserDataCopy] = useState([]);
    const [showUserEditModal, setShowUserEditModal] = useState(false);
    const [userFailedData, setUserFailedData] = useState([]);
    const [pageSize, setPageSizeChange] = useState(10);
    const [numPages, setNumPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const organisationId = '{{org-id}}';
    const [totalUser,setTotalUser] = useState(10);
    const [totalUserCopy,setTotalUserCopy] = useState(10);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [isButtonDisabled,setIsButtonDisabled] = useState(false)
    const [userData, setUserData] = useState({ });
    const [bulkUserData, setBulkUserData] = useState([]);
    const [searchedText, setSearchedText] = useState('');

    useEffect(() => {
        setCurrentPage(searchParams.get('page') || 1);
    }, [searchParams]);

    useEffect(() => {
        if (
            currentPage == searchParams.get('page') ||
            !searchParams.get('page')
        ) {
            fetchData(organisationId);
        }
    }, [currentPage, pageSize]);

    const getUsers = async () => {
        await fetchData('{{org-id}}');
    };

    useEffect(() => {
        getUsers()
        
        if (localStorage.getItem('userToken')) {
            dispatch(validateToken());
        } else {
            navigate('/login');
        }
    }, [orgStrId]);

    const addUserEditDataFunc = el => {
        const departmentIds = el?.role_map?.[0]?.departments?.map(department => department.id).join(',');
        const data = {
            id: el?.id,
            confirmed: true,
            first_name: el?.first_name,
            last_name: el?.last_name,
            employee_id: el?.employee_id,
            username: el?.username,
            unique_id:el?.username,
            email: el?.email,
            staus: el?.blocked,
            blocked:el?.blocked,
            password:el?.password,
            Supervised_By: el?.Supervised_By?.id,
            departments: departmentIds,
            organistation_roles:
                el?.role_map?.[0]?.organistation_roles?.[0]?.id,
            user_role: el?.role_map?.[0]?.user_role?.id,
        };
        setUserData(data);
    };

    const handleClose3 = () => {
        setShow3(false);
        setBulkUserData([]);
        setShowUserEditModal(false)
        setUserFailedData([])
    };

    const handleFileUpload = e => {
        const file = e.target.files[0];
        if (file) {
            const allowedExtensions = ['.xlsx', '.csv'];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
            if (allowedExtensions.includes(fileExtension)) {
                readUploadedFile(file);
            }
        }
    };

    const readUploadedFile = file => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = e => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            let excelData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                raw: false,
                cellDates: false,
                rawNumbers: true,
            });
            excelData = excelData.filter(row => row.length > 0);

            const dataToSet = [];
            const arrayMap = excelData.splice(0, 1);
            excelData.forEach((el, idx) => {
                const data = validateValues(el, arrayMap[0], idx + 1);
                dataToSet.push(data);
            });
            setBulkUserData(dataToSet);
        };
    };

    const isValidRegex = (key, value) => {
        let valid = true;
        switch (key) {
            case 'Supervisor':
                break;
            case 'first_name':
            case 'last_name':
            case 'One Time Password':
                valid =
                    value !== null &&
                    value !== undefined &&
                    value?.trim() !== '' &&
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                        value
                    );
                break;
            case 'employee_id':
            case 'Designation':
            case 'OBS':
            case 'Dashboard_role':
                valid = value !== null && value !== undefined;
                break;
            case 'email':
                valid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                );
                break;
            default:
                valid = false; // Invalid key
        }
        return valid;
    };

    function validateValues(arr, arrayMap, idx) {
        const validations = [];

        for (let i = 0; i < arr.length; i++) {
            const value = arr[i];
            const key = arrayMap[i];
            const valid = isValidRegex(key, value);
            validations.push({ name: key, value, valid, id: idx });
        }

        return validations;
    }

    const postData = async data => {
        const res = await client.post('/users', data);
        return res;
    };

    const putUserData = async data => {
        const res = await client.put(`/users/${data?.id}`, data);
        if (res.success) {
            if (searchedText) {
                handleSearchUser();
                setSearchedText('');
            } else {
                fetchData(organisationId);
            }
            handleCloseSingleUserModal();
            successNotify('User Updated Successfully!');
        } else {
            ErrorNotify('Something went wrong');
        }
    };

    const getUserDetails = bulkData => {
        function getNameAndValue(arr) {
            return arr.reduce((result, item) => {
                if (item.name && item.value) {
                    result[item.name] = item.value;
                }
                return result;
            }, {});
        }
        const userDetail = getNameAndValue(bulkData);
        return bulkDataApiFormat(userDetail, organisationId);
    };

    function bulkDataApiFormat(inputValue, organisationId) {
        return {
            confirmed: true,
            username: inputValue.employee_id,
            first_name: inputValue.first_name,
            last_name: inputValue.last_name,
            employee_id: inputValue.employee_id,
            unique_id:inputValue?.employee_id,
            email: inputValue.email,
            role: 1,
            temp_password: true,
            password: inputValue["One Time Password"],
            organisations: {
                connect: [
                    {
                        id: organisationId,
                    },
                ],
            },
            Supervised_By: inputValue.Supervisor,
            role_map: [
                {
                    departments: { id: inputValue.OBS },
                    organistation_roles: { id: inputValue.Designation },
                    user_role: { id: inputValue.Dashboard_role },
                },
            ],
        };
    }

    const handleUploadBulkData = async empty => {
        if (empty) {
            setUserFailedData([]);
            setBulkUserData([]);
            handleClose3();
            setIsButtonDisabled(false)
            return;
        }
        setIsButtonDisabled(true);

        const failedDataIndex = [];
        for (let i = 0; i < bulkUserData?.length; i++) {
            const payload = getUserDetails(bulkUserData[i]);
            const res = await postData(payload);
            failedDataIndex.push({
                idx: i,
                success: res?.success,
                msg: res?.error?.response?.data?.message,
            });
           
            setUserFailedData(failedDataIndex);
            setBulkUserData([...bulkUserData]);
        }
        await fetchData(organisationId);
        setIsButtonDisabled(false)
    };

    const handleSubmit = async (userData) => {
        if (showUserEditModal) {
            const data = formatAsAPI(userData, organisationId, dashboardRole,true);
            putUserData(data);
        } else {
            const data = formatAsAPI(userData, organisationId, dashboardRole);
            const res = await postData(data);
            if (res.success) {
                successNotify('User Created Successfully');
                handleCloseSingleUserModal();
                await fetchData(organisationId);
            } else {
                ErrorNotify(res?.error?.response?.data?.message);
            }
        }
    };

    const getOrganizationRole = async () => {
        const res = await client.get(
            `/permission-groups/getOrgRole/${organisationId}`
        );
        if (res.success) {
            const designation = (res?.data || []).filter(item => !item.Role_Name.startsWith('Sys.')); // for excluding designation with name Sys.
            setDesignation(designation);
        }
    };

    const getDashboardRole = async () => {
        const dROle = await client.get('/user-roles');
        setDashboardRole(dROle?.data);
    };

    const getSuperVisor = async () => {
        const res = await client.get(
            `/users?page=1&pageSize=1000&sort=username:ASC&filters[organisations]=${organisationId}`
        );
        setSupervisor(res?.data);
    };

    const fetchData = async id => {
        if (!id) return;
        setLoading(true);
        const response = await client.get(`/users/count?organisations=${id}`);
        const totalCount = response.data;
        setTotalUser(totalCount);
        setTotalUserCopy(totalCount);
        const pages = Math.ceil(totalCount / pageSize);
        setNumPages(pages);
        const startIndex =
            ((searchParams.get('page') || currentPage) - 1) * pageSize;
        const url = `/users?start=${startIndex}&limit=${pageSize}&sort=createdAt:DESC&filters[organisations]=${id}&populate=Supervised_By&populate=role_map&populate=role_map.departments&populate=role_map.organistation_roles&populate=role_map.user_role`;
        const res = await client.get(url);
        if (res.success) {
            setDashboardUserData(res?.data);
            setDashboardUserDataCopy(res?.data);
        }
        setLoading(false);
    };

    const exportAllData=async(id)=>{
        if (!id) return;
        const url = "/users-detail-export"
        const res = await client.get(url);
        if (res.success) {
            csvDownload({
                data: res?.data,
                filename: `${organization.organisationName} ${moment().format('DD-MMM-YY')}`,
                delimiter: ',',
                headers: extractKeys(res.data)
            })
        }
    }

    function extractKeys(array) {
        if (array.length === 0) {
            return [];
        }
        const firstObject = array[0];
        return Object.keys(firstObject);
    }

    const tHeader = (userHeader = false) => {
        return (
            <tr>
                <th>Sl. No</th>
                <th>First Name <sup className='sup_manatory text-danger'>*</sup></th>
                <th>Last Name <sup className='sup_manatory text-danger'>*</sup></th>
                <th>Employee ID <sup className='sup_manatory text-danger'>*</sup></th>
                <th>Supervisor</th>
                <th>Designation <sup className='sup_manatory text-danger'>*</sup></th>
                <th>OBS</th>
                {userHeader && <th>User Name <sup className='sup_manatory text-danger'>*</sup></th>}
                <th>Email ID <sup className='sup_manatory text-danger'>*</sup></th>
                {!userHeader && <th>One Time Password</th>}
                <th> Role</th>
                <th>Status</th>
                {userHeader && <th>Edit</th>}
            </tr>
        );
    };

    const tableData = data => {
        return data.map((el, id) => {
            return (
                <tr key={id}>
                    <td>{id + 1}</td>
                    {el.map((value, idx) => {
                        return (
                            <td
                                key={idx}
                                className={`text-start ${value?.valid
                                    ? ''
                                    : 'bg-soft-danger text-danger'
                                    }`}>
                                {value.value}
                            </td>
                        );
                    })}
                    <td>
                        {userFailedData?.length===0 ? "" : userFailedData[id]?.success ? (
                            <span style={{ color: 'green', cursor: 'pointer' }}>
                                {' '}
                                {toolTipHOC('Created', 'Created', true, 'top')}
                            </span>
                        ) : (
                            <span style={{ color: 'red', cursor: 'pointer' }}>
                                {toolTipHOC(
                                    'Not Created',
                                    (userFailedData[id]?.msg=="username is a required field" ? "Employee Id is a required field" : userFailedData[id]?.msg?.length>30 ? "Please check All the Field" : userFailedData[id]?.msg, true, 'top')
                                )}
                            </span>
                        )}
                    </td>
                </tr>
            );
        });
    };

    const handleGetDataToCreateUser = () => {
        getOrganizationRole();
        getDashboardRole();
        getSuperVisor();
    };

    const handleSearchUser = async () => {
        const res = await client.get(
            `/users?page=1&pageSize=1000&sort=username:ASC&filters[organisations]=${organisationId}&_q=${searchedText}&populate=Supervised_By&populate=role_map&populate=role_map.departments&populate=role_map.organistation_roles&populate=role_map.user_role&filters[role_map][departments][Is_Active][$eq]=true`
        );
        if (res?.success) {
            setDashboardUserData(res?.data);
            setTotalUser(res?.data?.length);
        }
    };

    return (
        <>
            <div className='page-wrapper no_overflow_x'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>Organization Setup</p>
                                <ul className='page_nav'>
                                    <DIYSideNav tab='user-management' />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    Setup / User Onboarding
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='two_column_title'>
                                    <h3 className='page_title'>
                                        User Management
                                    </h3>

                                    <div>
                                        <button
                                            className='btn bordered_btn'
                                            onClick={() => {
                                                handleShow4();
                                                handleGetDataToCreateUser();
                                            }}>
                                            Add Single User
                                        </button>
                                        {/* <button
                                            className='btn comman_btn ms-3'
                                            onClick={handleShow3}>
                                            Add Bulk User
                                        </button> */}
                                    </div>
                                </div>

                                <div
                                    className='d-flex justify-content-end'
                                    style={{
                                        marginTop: '15px',
                                        marginBottom: '-10px',
                                    }}>
                                        <div className='table_exp_area d-flex'>
                                                    <button
                                                        type='button'
                                                        className='btn exp_btn ms-3'
                                                        onClick={()=>exportAllData(organisationId)}>
                                                        <span>
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    '/images/excel_icon2.svg'
                                                                }
                                                            />
                                                        </span>{' '}
                                                        Export Users
                                                    </button>
                                                </div>
                                    <div className='useronboard_search ms-3'>
                                        <input
                                            className='form-control'
                                            value={searchedText}
                                            onKeyDown={e => {
                                                if (e.key == 'Enter') {
                                                    handleSearchUser();
                                                }
                                            }}
                                            onChange={e =>
                                                setSearchedText(e.target.value)
                                            }
                                            type='text'
                                            placeholder='Search By Email / User Name / First Name / Last Name'
                                        />
                                        {searchedText && (
                                            <button
                                                className='btn diy_emp_cancel_btn'
                                                onClick={() => {
                                                    setDashboardUserData(
                                                        dashboardUserDataCopy
                                                    );
                                                    setTotalUser(totalUserCopy);
                                                    setSearchedText('');
                                                }}>
                                                X
                                            </button>
                                        )}
                                        <button
                                            className='diy_emp_search_btn'
                                            onClick={handleSearchUser}>
                                            <i
                                                className='fa fa-search'
                                                aria-hidden='true'></i>
                                        </button>
                                    </div>
                                </div>

                                <div className='table_style_comman agent_tbl_scorewise scrollbar_style diy_user_tbl mt-4 mb-3'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>{tHeader(true)}</thead>
                                            <tbody>
                                                {loading
                                                    ? loadingData(12)
                                                    : dashboardUserData?.map(
                                                          (el, i) => (
                                                              <tr key={i}>
                                                                  <td>
                                                                      {(currentPage -
                                                                          1) *
                                                                          pageSize +
                                                                          (i +
                                                                              1)}
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {
                                                                          toolTipHOC(el?.first_name, el?.first_name, true, 'top')
                                                                      }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {toolTipHOC(el?.last_name, el?.last_name, true, 'top')}
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {toolTipHOC(
                                                                          el?.employee_id ||
                                                                              el?.username, el?.employee_id ||
                                                                              el?.username, true, 'top'
                                                                      )}
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {toolTipHOC(
                                                                          (el
                                                                              ?.Supervised_By
                                                                              ?.first_name ||
                                                                              '') +
                                                                          ' ' +
                                                                          (el
                                                                              ?.Supervised_By
                                                                              ?.last_name ||
                                                                              ''),
                                                                          (el
                                                                              ?.Supervised_By
                                                                              ?.first_name ||
                                                                              '') +
                                                                          ' ' +
                                                                          (el
                                                                              ?.Supervised_By
                                                                              ?.last_name ||
                                                                              ''), true, 'top'
                                                                      ) }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                    {
                                                                        toolTipHOC(el?.role_map?.[0]?.organistation_roles?.[0]?.Role_Name, el?.role_map?.[0]?.organistation_roles?.[0]?.Role_Name, true, 'top')
                                                                    }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {
                                                                         toolTipHOC( el
                                                                              ?.role_map?.[0]
                                                                              ?.departments.map(department => department.Department_Name).join(", "), el
                                                                              ?.role_map?.[0]
                                                                              ?.departments.map(department => department.Department_Name).join(", "), true, 'top')
                                                                      }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {
                                                                          toolTipHOC(el?.username, el?.username, true, 'top')
                                                                      }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {
                                                                          toolTipHOC(el?.email, el?.email, true, 'top')
                                                                      }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {
                                                                          el
                                                                              ?.role_map?.[0]
                                                                              ?.user_role
                                                                              ?.role
                                                                      }
                                                                  </td>
                                                                  <td className='text_ellipses'>
                                                                      {el?.blocked ? (
                                                                          <span
                                                                              style={{
                                                                                  color: 'red',
                                                                              }}>
                                                                              In-Active
                                                                          </span>
                                                                      ) : (
                                                                          <span
                                                                              style={{
                                                                                  color: 'green',
                                                                              }}>
                                                                              Active
                                                                          </span>
                                                                      )}
                                                                  </td>
                                                                  <td
                                                                      style={{
                                                                          cursor: 'pointer',
                                                                      }}
                                                                      onClick={() => {
                                                                          addUserEditDataFunc(
                                                                              el
                                                                          );
                                                                          handleShow4();
                                                                          handleGetDataToCreateUser();
                                                                          setShowUserEditModal(
                                                                              true
                                                                          );
                                                                      }}>
                                                                        <img alt="" src={imagePath("/images/edit_pencil.svg")}></img>
                                                                      {/* <CiEdit /> */}
                                                                  </td>
                                                              </tr>
                                                          )
                                                      )}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                {(dashboardUserData?.length>=10 || currentPage!==1)&& <PaginationBar
                                        nPages={numPages}
                                        currentPage={currentPage}
                                        base={`/o/${orgStrId}/setup/user-management`}
                                        pageSize={pageSize}
                                        onPageSizeChange={setPageSizeChange}
                                        totalUser={totalUser}
                                        paginationForDIY = {true}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <Modal
                show={show4}
                onHide={() => {
                    handleCloseSingleUserModal();
                }}
                backdrop='static'
                keyboard={false}
                className='obs_single_user_modal'>
                <Modal.Header closeButton>
                    <h4 className='mt-4 text-primary'>{showUserEditModal ? 'Edit User' : 'Add User'}</h4>
                </Modal.Header>
                <Modal.Body>
                    <UserForm
                        handleSubmit={handleSubmit}
                        dashboardRole={dashboardRole}
                        designation={designation}
                        supervisor={supervisor}
                        userDataForEdit={userData}
                        handleCloseSingleUserModal={handleCloseSingleUserModal}
                        showUserEditModal={showUserEditModal}
                    />
                </Modal.Body>
            </Modal>

            {/* bulk user upload modal */}
            <Modal
                show={show3}
                onHide={handleClose3}
                backdrop='static'
                keyboard={false}
                className='obs_single_user_modal_bulk'>
                <Modal.Header closeButton>
                    <h4 className='mt-4 text-primary'>Upload Users Data</h4>
                </Modal.Header>
                <Modal.Body>
                    {bulkUserData?.length > 0 ? (
                        <div className='audit_list_view'>
                            <div className='tab_view'>
                                <Tab.Container defaultActiveKey='first'>
                                    <Nav
                                        variant='pills'
                                        data-toggle='slider-tab'
                                        role='tablist'>
                                        <Nav.Item role='presentation'>
                                            <Nav.Link
                                                eventKey='first'
                                                variant='  d-flex align-items-center'
                                                data-bs-toggle='tab'
                                                data-bs-target='#pills-list_audit'
                                                type='button'
                                                role='tab'
                                                aria-controls='home'
                                                aria-selected='true'>
                                                All
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className='mt-4'>
                                        <Tab.Pane
                                            eventKey='first'
                                            variant=' fade show active'
                                            id='pills-list_audit'
                                            role='tabpanel'
                                            aria-labelledby='pills-list_audit-tab1'>
                                            <div className='table_style_comman obs_user_list scrollbar_style'>
                                                <div className='table-responsive'>
                                                    <table className='table text-center'>
                                                        <thead>
                                                            {tHeader()}
                                                        </thead>
                                                        <tbody>
                                                            {tableData(
                                                                bulkUserData
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='text-end'>
                                <button className='btn bordered_btn' onClick={()=>{
                                            // const sampleFileDownload = () => {
                                                const fileUrl = "/userDataSample.xlsx";
                                                const anchor = document.createElement("a");
                                                anchor.href = fileUrl;
                                                anchor.download = "userDataSample.xlsx";
                                                anchor.click();
                                            //   };

                                        }}>
                                    {/* <img
                                        src={imagePath(
                                            '/images/add_icon_blue.svg'
                                        )}
                                        className='me-1'
                                        
                                    />{' '} */}
                                    Download Sample CSV
                                </button>
                            </div>

                            <div className='drag_file_upload_area text-center mt-4'>
                                <img
                                    src={imagePath('/images/multi_upload_icon.svg')}
                                    alt=''
                                />
                                <h5 className='h5 mt-3'>
                                    Upload CSV or drag and drop
                                </h5>
                                <p>CSV (max 5MB) or XLSX/XLS (max 5MB) </p>
                                <div className='file_upload_custom'>
                                    <button className='btn'>
                                        Upload a file
                                    </button>
                                    <input
                                        type='file'
                                        name='myfile'
                                        accept='.xlsx, .csv'
                                        onChange={handleFileUpload}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {/*  */}
                    <div className='btn_right mt-4'>
                        <Button
                            disabled={(isButtonDisabled)}
                            onClick={() =>{
                                handleUploadBulkData(
                                    userFailedData?.length > 0 ? 'empty' : ''
                                )
                                
                            }}
                            className='btn comman_btn'>
                            {/* {userFailedData?.length > 0 ? 'Close' : 'Next'} */}
                            {isButtonDisabled ? (
                                <>
                                    <Spinner className='spinner-button' style={{color:'blue'}}></Spinner>
                                    <span> Next</span>
                                </>
                            ) : (
                                <>
                                {userFailedData?.length > 0?'Close':'Next'}
                                </>
                            )}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DiyLandingScreen;

function formatAsAPI(inputValue, organisationId, dashboardRole,update=false) {
    const apiData = {
        id: inputValue?.id,
        blocked: inputValue?.blocked,
        confirmed: true,
        username:inputValue?.employee_id,
        unique_id:inputValue?.employee_id,
        first_name: inputValue.first_name,
        last_name: inputValue.last_name,
        employee_id: inputValue.employee_id,
        email: inputValue.email,
        role: 1,
        organisations: {
            connect: [
                {
                    id: organisationId,
                },
            ],
        },
        role_map: [
            {
                departments: inputValue?.departments ? reFactorDepartment((inputValue?.departments+"")?.split(",")) : { id: null },
                organistation_roles: { id: inputValue.organistation_roles },
                user_role: { id: inputValue.user_role },
            },
        ],
    };

    if (inputValue.Supervised_By) {
        apiData.Supervised_By = inputValue.Supervised_By;
    }
    if (!update) {
        apiData.password = "Password@123"
        apiData.temp_password = true;
    } else if (inputValue?.password) {
        apiData.password = inputValue.password;
        apiData.temp_password = true;
    }
    
    return apiData;
}

const reFactorDepartment = (arr)=>{
    const positiveArr = arr.map(numStr => Math.abs(parseInt(numStr)));
    if (positiveArr.length > 0 && positiveArr[0] === 1) {
        positiveArr.shift(); 
    }
    return positiveArr
}

