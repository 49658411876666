import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import authReducer from './auth/reducers'
import auditSheetReducer from './audit-sheet/reducers'
import manualIntegrationReducer from './slices/manualIntegrationSlice';
import qaDashboardSlice from './slices/qadashBoardSlice'

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    auditSheet: auditSheetReducer,
    auth: authReducer,
    manualIntegration: manualIntegrationReducer,
    qaDashboard: qaDashboardSlice
  }
});
