import { ShowerRounded } from '@mui/icons-material';
import * as React from 'react';
import { Form } from 'react-bootstrap';
const { withJsonFormsControlProps } = require('@jsonforms/react');
const {
    rankWith,
    isStringControl,
    isNumberControl,
    or,
} = require('@jsonforms/core');

const InputFormCtrl = props => {
    const {
        placeholder = '',
        type = 'string',
        handleChange,
        require,
        disabled,
        showError,
    } = props.schema;
    const { label = '', data } = props;

    return (
        <>
            <Form.Label md='6' htmlFor='validationDefault01'>
                {label}
                {require && <sup className='sup_manatory text-danger'>*</sup>}
            </Form.Label>
            <Form.Control
                key={`${JSON.stringify(props.schema)}_${JSON.stringify(props.uischema)}`}
                type={type}
                placeholder={`Enter ${placeholder}`}
                id='validationDefault01'
                name={placeholder}
                defaultValue={data}
                disabled={disabled}
                onKeyDown={e => {
                    if (type === 'number' && e.key == 'e') {
                        e.preventDefault();
                    }
                }}
                onChange={handleChange}
                required={require}
            />
            <Form.Control.Feedback type='invalid'>
                Please enter {label}
            </Form.Control.Feedback>
        </>
    );
};

export const InputFormControl = withJsonFormsControlProps(InputFormCtrl);

export const InputFormTester = rankWith(
    3, // increase rank as needed
    or(isStringControl, isNumberControl)
);
