import { createSlice } from '@reduxjs/toolkit'

const manualIntegrationSlice = createSlice({
    name: 'manualIntegration',
    initialState: {
        validRows: [],
        uploadedFileName: [],
        applyFilterData: [],
        filteredData: [],
        chosenDepartent: null,
        responseAfterCRMImports: [],
        selectedDepartmentState: {},
        selectedFilterForEdit:{}
    },
    reducers: {
        setValidRows(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.validRows = action.payload;
            return updatedInfo;
        },
        setFilterSelectedDepartment(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.chosenDepartent = action.payload; 
            return updatedInfo;
        },
        setUploadedFileName(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.uploadedFileName = action.payload;
            return updatedInfo;
        },
        setApplyFilterData(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.applyFilterData = action.payload;
            return updatedInfo
        },
        filteredDataAfterFilteration(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.filteredData = action.payload;
            return updatedInfo
        },
        setResponseAfterCRMImports(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.responseAfterCRMImports = action.payload;
            return updatedInfo
        },
        setSelectedDepartmentState(state, action) {
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.selectedDepartmentState = action.payload;
            return updatedInfo
        },
        setSelectedFilterForEdit(state,action){
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.selectedFilterForEdit = action.payload;
            return updatedInfo
        }
    }
})

export const {
    setValidRows,
    setUploadedFileName,
    setApplyFilterData,
    filteredDataAfterFilteration,
    setSelectedDepartmentState,
    setResponseAfterCRMImports,
    setSelectedFilterForEdit,
    setFilterSelectedDepartment

} = manualIntegrationSlice.actions;

export default manualIntegrationSlice.reducer
