const rebuttalStyle = {
    backgroundColor: '#ECEAD9',
    borderRadius: '#817400',
    color: '#817400',
};
const fatalStyle = {
    backgroundColor: '#FFEEEE',
    borderRadius: '#DA3B01',
    color: '#DA3B01',
    border: '1px solid #DA3B01',
};

// average-audit-dashboard
export const AverageAuditDashboardProps = {
    breadcrumb: [
        { title: 'Dashboard', image: '/images/home_icon_pagig.svg', link: '/' },
        { title: 'Dashboard', image: '', link: '/' },
        { title: 'Average Audit Score', image: '', link: '' },
    ],
    lastDivStyle: rebuttalStyle,
    option: {
        obs: true,
        date: true,
        audit: true,
    },
    title: 'Average audit score dashboard',
    countData: [
        { name: 'QA Count', value: '0' },
        { name: 'Total Audit', value: '0' },
        { name: 'Audit Pending', value: '0' },
        { name: 'Avg Score', value: '0' },
        { name: 'Rebuttal Pending', value: '0' },
    ],
    url: ['dashboard'],
};

// rebuttal-pending-dashboard
export const RebuttalPendingDashboardProps = {
    breadcrumb: [
        { title: 'Dashboard', image: '/images/home_icon_pagig.svg', link: '/' },
        { title: 'Dashboard', image: '', link: '/' },
        { title: 'Rebuttal Pending', image: '', link: '' },
    ],
    lastDivStyle: rebuttalStyle,
    option: {
        obs: true,
        date: true,
        audit: true,
    },
    title: 'Rebuttal pending dashboard',
    countData: [
        { name: 'QA Count', value: '0' },
        { name: 'Total Audit', value: '0' },
        { name: 'Audit Pending', value: '0' },
        { name: 'Avg Score', value: '0' },
        { name: 'Rebuttal Pending', value: '0' },
    ],
    url: ['dashboard'],
};

// agent-score-dashboard
export const AgentScoreDashboardProps = {
    breadcrumb: [
        { title: 'Dashboard', image: '/images/home_icon_pagig.svg', link: '/' },
        { title: 'Quality Function Dashboard', image: '', link: 'orgStrId' },
        { title: 'Agent', image: '', link: '' },
    ],
    lastDivStyle: fatalStyle,
    option: {
        obs: true,
        date: true,
        audit: true,
        qaDashBoard: true,
        filterDropDown: ['Score Wise', 'Error Wise', 'Acceptance Pending'],
    },
    title: 'Agent score wise dashboard',
    countData: [
        { name: 'Agent Count', value: '0' },
        { name: 'Total Audit', value: '0' },
        { name: 'Avg Score', value: '0' },
        { name: 'Total Fatal', value: '0' },
    ],
    url: ['agent_scorewise_dashboard', 'agent_scorewise_datewise_dashboard'],
};

// qa-score-dashboard
export const QaScoreDashboardProps = {
    breadcrumb: [
        { title: 'Dashboard', image: '/images/home_icon_pagig.svg', link: '/' },
        { title: 'Quality Function Dashboard', image: '', link: 'orgStrId' },
        { title: 'Quality Associates', image: '', link: '' },
    ],
    lastDivStyle: rebuttalStyle,
    option: {
        obs: true,
        date: true,
        audit: true,
        filterDropDown: ['Audit Score', 'Audit Pending'],
    },
    title: 'Quality associates audit score dashboard',
    countData: [
        { name: 'QA Count', value: '0' },
        { name: 'Total Audit', value: '0' },
        { name: 'Avg Score', value: '0' },
        { name: 'Rebuttal Pending', value: '0' },
    ],
    url: ['qa_wise_dashboard_header_widget', 'qa_wise_dashboard'],
};

// parameter-wise-fatal-dashboard
export const ParameterWiseFatalDashboardProps = {
    breadcrumb: [
        { title: 'Dashboard', image: '/images/home_icon_pagig.svg', link: '/' },
        { title: 'Quality Function Dashboard', image: '', link: 'orgStrId' },
        { title: 'Fatal Dashboard', image: '', link: '' },
    ],
    lastDivStyle: rebuttalStyle,
    option: {
        obs: true,
        date: true,
        audit: true,
        filterDropDown: [
            'Parameter Wise',
            'Repeated Error',
            'Audit Acceptance',
        ],
    },
    title: 'Parameter wise fatal dashboard',
    countData: [
        { name: 'Total Fatal', value: '0' },
        { name: 'Repeated Error', value: '0' },
        { name: 'Audit Acceptance', value: '0' },
        { name: 'Rebuttal Pending', value: '0' },
    ],
    url: ['fatal_dashboard/subparam'],
    refreshButton: true,
};
