import { useMemo, useState } from "react";

const CustomDataTable = ({ tableKey, isLoading, alternate, options ,addClass=false }) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [dummyHead, setDummyHead] = useState([]);
  useMemo(() => {
    if (options?.columns) {
      setColumns(options.columns);
    }
    if (options?.data) {
      setData(options.data);
    }
    const temp = [];
    for (let i = 0; i < (options?.columns?.length || 6); i++) {
      temp.push(i);
    }
    setDummyHead(temp);
  }, [options]);

 

  return (
    <div className="table_style_comman agent_tbl_scorewise">
      <div className="table-responsive">
        <table className={`${"table" + (alternate ? " alternate" : "")} ${addClass ? addClass : ''}`}>
          <thead>
            <tr>
              {columns.map((col,idx) => {
                return (
                  <th style={col.style} key={col.title+idx}>
                    <strong>{col.title}</strong>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [0, 1, 2, 3, 4].map((temp) => {
                return (
                  <tr className="p_relative" key={"row-ld-" + temp}>
                    {dummyHead.map((temp2) => (
                      <td key={"row-ld-" + temp2}>
                        <div style={{ height: "10px" }} className="shimmer">
                          &nbsp;
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <>
                {(data || []).map((row, idx) => {
                  return (
                    <tr className="p_relative" key={"row-" + idx}>
                      {Object.keys(row).map((colName) => {
                        return row[colName].value == null ? null : row[colName]
                            .isComponent ? (
                          <td
                            rowSpan={row[colName].rowspan}
                            className={row[colName].className}
                            key={"comp_" + idx + "_" + tableKey}
                          >
                            {row[colName].value}
                          </td>
                        ) : row[colName].value.label ? (
                          <td
                            rowSpan={row[colName].rowspan}
                            className={row[colName].className}
                          >
                            {row[colName].value.label}
                          </td>
                        ) : (
                          <td
                            rowSpan={row[colName].rowspan}
                            className={row[colName].className}
                          >
                            {row[colName].value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomDataTable;
