import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import { client } from '../../services/apiService';
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setOrganizationId(state, action) {
            console.log("ORGANIZATION ID: ", action.payload);
            const updatedInfo = JSON.parse(JSON.stringify(state))
            updatedInfo.organizationId = action.payload;
            return updatedInfo;
        },
        getLoggedInUserSettings(state, action) {
            console.log("I am called", state.isLoggedIn);
        },
        setLoginError(state, action) {
            state.loginError = action.payload;
        },
        getAudioConfig(state, action) {
            let org = JSON.parse(JSON.stringify(state.organization));
            state.audio_config = findDepartment(org.departments , action.payload)?.Audio_Config;
            state.columns = findDepartment(org.departments , action.payload)?.Audio_Config?.columns || initialState.columns;
        },
        updateTempPassword(state, action) {
            console.log("🚀  action.payload", action.payload)
            state.loggedInUser = {
                ...state.loggedInUser,
                tempPassword: action?.payload?.temp_password , 
            }
        },
    },
    extraReducers(builder) {
        // omit posts loading reducers
        builder.addCase(validateToken.fulfilled, (state, action: any) => {
            if (action.payload?.data?.email) {
                let org = action.payload.data.organisations[0];
                if (org) {
                    state.isLoggedIn = true;
                    state.organization = {
                        organisationName: org.Organisation_Name,
                        orgStrId: org.Org_str_id,
                        departments:  org.Departments
                    }
                    state.prefix = org.Import_Mapping?.prefix,
                    state.loggedInUser = {...state.loggedInUser,
                        userName: action.payload.data.username,
                        email: action.payload.data.email,
                        role: org.Role_Name,
                        orgRole: org.Custom_Role,
                        firstName: action.payload.data.first_name,
                        lastName: action.payload.data.last_name,
                        tempPassword: action.payload.data.temp_password,
                    }
                    state.accessPermissions = org.Access_Permissions,
                    state.metabase = org.Metabase,
                    state.allDeparmentMetaInfo = org.allDeparment_Meta_Info,
                    state.userMetaInformation = action.payload.data.Metabase_Info
                    state.loginError = null;
                    // if (org.Import_Mapping?.columns) {
                    //     state.columns = org.Import_Mapping?.columns;
                    // }
                } else {
                    state.loginError = "No organisation is mapped with this user.";
                    state.isLoggedIn = false;
                }
                
            } else {
                state.isLoggedIn = false;
                state.organization = {
                    organisationName: null,
                    orgStrId: null,
                    departments: null
                }
            }

        })

        builder.addCase(loginUser.fulfilled, (state, action: any) => {
            if (action.payload.success) {
                const { jwt } = action.payload.data;
                localStorage.setItem("userToken", jwt)
                state.loggedInUser.currentPassword = action.payload.data.currentPassword
            }
            else{
                state.loginError = action.payload?.error?.response?.data?.message
            }
        });
    }
})

export const validateToken = createAsyncThunk('auth/validateToken', async () => {
    const response = await client.get('/user-settings')
    return response;
})


export const loginUser = createAsyncThunk('auth/login', async ({userName, password}:any, {dispatch}) => {
    const response = await client.post('/auth/local', {
        identifier: userName,
        password: password,
    }, undefined, false);
    if (response.data && response.success) {
        const { jwt } = response.data;
        localStorage.setItem("userToken", jwt)
        dispatch(validateToken());
        response.data.currentPassword = password
    } 
    return response
})

export const { updateTempPassword } = authSlice.actions;

const findDepartment = (depts, deptId) => {
    for (let dept of depts) {
        if (dept.id == deptId) {
            return dept;
        }
        if (dept.Departments) {
            let tempDepartment = findDepartment(dept.Departments, deptId)
            if (tempDepartment) {
                return tempDepartment;
            }
        }
    }
}

export default authSlice.reducer;