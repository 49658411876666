import React from 'react';
import { Accordion, Breadcrumb, } from 'react-bootstrap';
import 'react-dropdown-tree-select/dist/styles.css';
import { imagePath } from '../../CommonLogic';
import DIYSideNav from './DIYSideNav';
import './diy.css';
import EditableTreeOrg from './EditableTreeOrg';

const DIYOrganizationSetup = () => {
    return (
        <>
            <div className='page-wrapper no_overflow_x page_loader--'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>Organization Setup</p>
                                <ul className='page_nav'>
                                    <DIYSideNav tab='org-setup' />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    Setup / Organization Setup
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='two_column_title page_title_box'>
                                    <h3 className='page_title'>
                                        Organization Breakdown Structure
                                    </h3>
                                </div>
                                <div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <div className='obs_user_creations '>
                                            <Accordion defaultActiveKey='0'>
                                                <Accordion.Item eventKey='0'>
                                                    <Accordion.Body>
                                                        <EditableTreeOrg />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DIYOrganizationSetup;
