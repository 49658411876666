export const isLoggedIn = (state): Boolean => state.auth.isLoggedIn;
export const organization = (state): any => state.auth.organization;
export const orgStrId = (state): String => state.auth.organization?.orgStrId;
export const obs = (state): any => state.auth.organization?.departments;
export const loggedInUser = (state): any => state.auth.loggedInUser;
export const loginError = (state): any => state.auth.loginError;
export const accessPermissions = (state): any => state.auth.accessPermissions;
export const columns = (state):any => state.auth.columns;
export const prefix = (state):any => state.auth.prefix;
export const audio_config = (state):any => state.auth.audio_config;
export const metabaseAccess = (state):any => state.auth.metabase;
export const userMetaInformation = (state):any => state.auth.userMetaInformation;
export const allDeparmentMetaInfo = (state):any => state.auth.allDeparmentMetaInfo;