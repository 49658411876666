import React, { useState } from 'react'
import { toolTipHOC } from '../../CommonLogic';

const AnalyticsSideNav = ({splitByData, splitBy, handleFilter, active, setActive}) => {
    return (
        <>
            {splitByData?.map((el,i) => (
                <li key={el?.SBU} onClick={()=>{
                    handleFilter(el);
                    setActive(i)
                }}>
                    <a className={active == i ? 'active text-ellipsis' : 'text-ellipsis'} href='#' >
                    {toolTipHOC(el?.[splitBy], el?.[splitBy], true, "top")}
                        {/* {el?.[splitBy]} */}
                    </a>
                </li>
            ))}
        </>
    );
}

export default AnalyticsSideNav